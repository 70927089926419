:root {
    --timeline-element-height: 4.6em;
}

.admin-menu {
    width: 100%;
}

.action-bar {
    display: flex;
    align-items: center;
    border-radius: 1em;
    padding: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.action-bar.proposal-row-yellow {
    border: 2px #885601 solid;
    color: black;
    grid-column: span 3 / auto;
}

.action-bar.proposal-row-red {
    border: 2px #fd2723 solid;
    color: black;
    grid-column: span 3 / auto;
}

.action-bar.proposal-row-green {
    border: 2px #2c662d solid;
    font-size: 0.85rem;
    color: black;
    grid-column: span 2 / auto;
}

.action-bar.proposal-row-gray {
    border: 2px #484848 solid;
    font-size: 0.85rem;
    color: #000000;
    grid-column: span 2 / auto;
}

.action-bar-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.actions-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--timeline-element-height), 1fr));
    grid-auto-rows: var(--timeline-element-height);
    gap: 0.6rem;
    margin-bottom: 1em;
}

.view-action-button {
    min-height: 2.5em !important;
}

.relevant-actions-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--timeline-element-height), 1fr));
    gap: 0.6rem;
}

.no-popup-tooltip {
    grid-column: span 3 / auto;
    padding: 0.5em;
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;
}

.no-popup-tooltip .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.tabular.menu {    
    overflow-y: hidden;
    overflow-x: auto;
}

.submission-errors {
    color: red;
}

.announcement-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25em
}

.announcement {
    width: 100%;
    background-color: #fff6f6;
    border-radius: 0.5em;
    padding: 0.5em;
    margin-bottom: 0.25em;
}

.announcement .announcement-header {
    display: flex;
    margin-bottom: 0.5em;
}

.announcement .announcement-header :first-child {
    flex-grow: 1;
    padding-right: 0.25em;
}

.announcement .announcement-header * {
    margin: 0;
}

.ui.dimmer .ui.workaround.loader {
    color: rgba(0, 0, 0, 0.7);
}

.ui.dimmer .ui.workaround.loader:before {
    border-color: rgba(0,0,0,.1);
}

.ui.dimmer .ui.workaround.loader:after {
    border-color: #767676 transparent transparent;
}

.sponsor-note-actions {
    display: flex;
    align-items: center;
    padding: 10px;
}

.announcement-container-wrapper {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}